import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors, toastError } from '@/utils';

const {
  getVehicleEquipment,
  postVehicleEquipment,
  putVehicleEquipment,
  deleteVehicleEquipment,
} = backOffice.vehicleEquipment;

export const EQUIPMENT_SCOPES = {
  equipment: 'equipment',
  deleteEquipment: 'deleteEquipment',
};

const equipments = createCustomStore(({ runAsyncFlow }) => withScopes(EQUIPMENT_SCOPES, ({
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(state, payload, [EQUIPMENT_SCOPES.deleteEquipment]);
    },
    clearErrors,
  },
  actions: {
    async getVehicleEquipment({ commit }) {
      await runAsyncFlow(commit, {
        request: getVehicleEquipment,
      });
    },
    async postVehicleEquipment({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postVehicleEquipment,
        params: [data],
        scope: EQUIPMENT_SCOPES.equipment,
      });
    },
    async putVehicleEquipment({ commit }, { equipmentUuid, data }) {
      await runAsyncFlow(commit, {
        request: putVehicleEquipment,
        params: [equipmentUuid, data],
        scope: EQUIPMENT_SCOPES.equipment,
      });
    },
    async deleteVehicleEquipment({ commit }, equipmentUuid) {
      await runAsyncFlow(commit, {
        request: deleteVehicleEquipment,
        params: [equipmentUuid],
        scope: EQUIPMENT_SCOPES.deleteEquipment,
      });
    },
  },
})));

export default equipments;
